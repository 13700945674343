import React from 'react';
import './App.css';
import { Route, Routes, BrowserRouter} from 'react-router-dom';
import Hiya from './hiya/hiya';
import Main from './main/main';
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main/>} />
      <Route path='hiya' element={<Hiya/>} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
