// Hangman.js

import React from 'react';
import styles from './Terminal.module.css';

const Hangman = ({ attemptsLeft }) => {
  const hangmanStages = [
    `
      _______
     |      |
     |      O
     |     
     |     
     |      
    _|___
    `,
    `
      _______
     |      |
     |      O
     |      |
     |     
     |      
    _|___
    `,
    // Add more Hangman stages as needed
  ];

  return (
    <div className={styles.hangman}>
      <pre>{hangmanStages[hangmanStages.length - attemptsLeft]}</pre>
    </div>
  );
};

export default Hangman;
