// commands.js
const hangman = {
    word: '',
    guessedLetters: [],
    maxAttempts: 6,
    attemptsLeft: 6,
  
    start: (word) => {
      hangman.word = (word || '').toLowerCase(); // Ensure word is defined before calling toLowerCase
      hangman.guessedLetters = [];
      hangman.attemptsLeft = hangman.maxAttempts;
  
      const initialDisplay = Array(hangman.word.length).fill('_').join(' ');
      const hangmanArt = `

      ╔╗╔╗───────╔═╦═╗
      ║╚╝╠═╗╔═╦╦═╣║║║╠═╗╔═╦╗
      ║╔╗║╬╚╣║║║╬║║║║║╬╚╣║║║
      ╚╝╚╩══╩╩═╬╗╠╩═╩╩══╩╩═╝
      ─────────╚═╝
      
      `;
  
      return `Hangman game started! ${initialDisplay}\n${hangmanArt}`;
    },
  
    guess: (letter) => {
      if (!hangman.word.includes(letter)) {
        hangman.attemptsLeft--;
      }
  
      hangman.guessedLetters.push(letter);
  
      const display = hangman.word
        .split('')
        .map(char => (hangman.guessedLetters.includes(char) ? char : '_'))
        .join(' ');
  
      let result = `Attempts Left: ${hangman.attemptsLeft}\n${display}`;
  
      if (display === hangman.word) {
        result += '\nCongratulations! You guessed the word!';
        hangman.start('');
      } else if (hangman.attemptsLeft === 0) {
        result += `\nGame Over! The word was: ${hangman.word}`;
        hangman.start('');
      }
  
      return result;
    },
  };
  
const commands = {
  help: () => {
    return 'Available commands: \n help\n clear\n coolcmd\n about\n date\n echo\n joke\n hangman';
  },
  clear: () => {
    return { clear: true };
  },
  coolcmd: () => {
    return '😎 Command executed!';
  },
  about: () => {
    return 'DeSi Terminal v1.0. A custom terminal in React.';
  },
  date: () => {
    return `Current Date and Time: ${new Date().toLocaleString()}`;
  },
  echo: (...args) => {
    return args.join(' ');
  },
  joke: () => {
    const jokes = [
      "Why do programmers prefer dark mode? Because light attracts bugs.",
      "Why don't programmers like nature? It has too many bugs.",
      "How do you comfort a JavaScript bug? You console it."
    ];
    return jokes[Math.floor(Math.random() * jokes.length)];
  },
  hangman: hangman.guess,
  default: (cmd) => {
    return `Command not found: ${cmd}`;
  },

  Hiya: () => {
    return 'Itne bde log, naam bhi captial se start kr rhe ho. chup chap hiya likho mam'
  },

};

export { commands, hangman };