import React, { useState, useEffect, useRef } from 'react';
import styles from './Terminal.module.css';
import { commands, hangman } from './commands';
import Hangman from './Hangman';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Terminal = () => {
    const [input, setInput] = useState('');
    const [lines, setLines] = useState([]);
    const [history, setHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(0);
    const [console, setConsole] = useState('terminal'); // Default console mode

    const inputRef = useRef(null);
    const navigate = useNavigate(); // Initialize useNavigate here

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const executeCommand = (input) => {
        const [command, ...args] = input.split(' ');
        const action = commands[command] || commands.default;

        if (command === 'hangman') {
            setConsole('hangman');
            setLines([input, hangman.start(...args)]);
        } else if (command === 'hiya') {
            // Use navigate function instead of useNavigate
            navigate('/hiya');
        } else {
            const result = action(...args);

            if (result.clear) {
                setLines([]);
            } else {
                setLines([...lines, input, result]);
            }
        }
    };

    const handleKeyDown = (e) => {
        const { keyCode } = e;

        if (keyCode === 13) { // Enter key
            e.preventDefault();
            executeCommand(input);
            setHistory([...history, input]);
            setHistoryIndex(history.length + 1);
            setInput('');
        } 
        else if (keyCode === 38) { // Up arrow key
            e.preventDefault();
            const newIndex = historyIndex - 1 >= 0 ? historyIndex - 1 : 0;
            setHistoryIndex(newIndex);
            setInput(history[newIndex] || '');
        } 
        else if (keyCode === 40) { // Down arrow key
            e.preventDefault();
            const newIndex = historyIndex + 1 < history.length ? historyIndex + 1 : history.length;
            setHistoryIndex(newIndex);
            setInput(history[newIndex] || '');
        }
    };  

    return (
    <div className={styles.terminal}>
        {console === 'terminal' ? (
        <>
            <div className={styles.terminalHeader}>
            <div className={styles.red}></div>
            <div className={styles.yellow}></div>
            <div className={styles.green}></div>
            </div>
            <div className={styles.terminalBody}>
            <div className={styles.terminalText}>
                <p>
                Welcome to DeSi Terminal v1.0
                </p>
                <p>
                Type "help" to get started.
                </p>
            </div>
            </div>
            
            <div className={styles.history}>
            {lines.map((line, index) => (
                <div key={index}>DeSi/user&gt;{line}</div>
            ))}
            </div>
            <div className={styles.inputLine}>
            DeSi/user&gt;
            <input
                ref={inputRef}
                className={styles.terminalInput}
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            </div>
        </>
        ) : console === 'hangman' ? (
        <div className={styles.hangmanConsole}>
            {lines.map((line, index) => (
            <div key={index}>Hangman&gt;{line}</div>
            ))}
            <Hangman attemptsLeft={hangman.attemptsLeft} />
        </div>
        ) : null}
    </div>
    );
};

export default Terminal;
