import React from 'react'
import styles from './main.module.css'
import Terminal from '../terminal/Terminal'
import { Github } from 'lucide-react'
import { Linkedin } from 'lucide-react'
import { Instagram } from 'lucide-react'
import { Send } from 'lucide-react'
import { Mail } from 'lucide-react'
import CursorBackground from './cursor'

function Main() {
  return (
    <div className={`main ${styles.main} w-full min-h-screen m-0 absolute top-0 left-0 overflow-x-hidden`}>
      <CursorBackground />
      <SideLinks />
      <div className={`${styles.head} w-full md:w-5/6 h-auto md:h-4/5 flex flex-col md:flex-row mx-auto mt-4 md:mt-10 lg:mt-20 z-10`}>
        <div className={`${styles.headingtext} w-full md:w-1/2 text-center md:text-left my-auto md:mt-20 lg:mt-0 md:pl-8`}>
          <h1 className='text-4xl md:text-5xl lg:text-7xl mt-4 md:mt-10 lg:relative lg:left-1/5'>Deepak Silaych</h1>
          <p className='mt-6 md:mt-10 lg:w-4/5 xl:w-3/4'>
            I am a passionate developer entusiatic about blockchain technology and with experince in full-stack web development. With a foundation in decentralized platforms, machine learning, and modern web technologies, I am challenge driven and always eager to learn.
          </p>
        </div>
        <div className={`${styles.terminal} w-full md:w-1/2 mt-10`}>
          <Terminal />
        </div>
      </div>
      <div className={` ${styles.experince} mt-20 w-full overflow-hidden relative mx-auto z-20`}>
        <table className='w-full flex justify-end'>
          <tbody className={`${styles.tbody} w-5/6`}>
            <tr>
              <td colSpan="2">
                <h1 className={`text-3xl lg:text-5xl ml-10 lg:ml-40 mt-20 mb-10`}>Experience</h1>
              </td>
            </tr>
            <ExperienceRow
              date='July 2024 - Present'
              title='Software Developer Intern | DraftAi'
              description='Developed a document editing web application with React and Syncfusion Document Editor, implementing real-time synchronization. Integrated Aadhaar ID using PKI for authentication and built an NLP-based JS library for PDF content editing.'
              skills={['React', 'Syncfusion', 'PKI', 'NLP']}
            />
            <ExperienceRow
              date='Aug 2023 - Sept 2023'
              title='Web Developer Intern | Healthka'
              description='Revamped Healthka’s website from a static to dynamic layout using React JS. Integrated key APIs and improved admin dashboard features like search sorting and responsive UI.'
              skills={['ReactJS', 'APIs', 'JavaScript']}
            />
            <ExperienceRow
              date='Apr 2024 - Jul 2024'
              title='Mumbai Flood Warning System | Climate Studies IITB'
              description='Led technical development of a rainfall and flood monitoring system for Mumbai, including a React web platform and a mobile app. Integrated machine learning models for real-time predictions using Django and Celery for task automation.'
              skills={['React', 'Django', 'Celery', 'Machine Learning']}
            />

            <tr>
              <td colSpan="2">
                <h1 className={`text-3xl lg:text-5xl ml-10 lg:ml-40 mt-20 mb-10`}>Education</h1>
              </td>
            </tr>
            <EducationRow
              institution='Indian Institute of Technology Bombay'
              degree='Bachelor of Technology (BTech)'
              years='2026 (Expected)'
            />
            <EducationRow
              institution='Matrix High School, Sikar'
              degree='High School (12th)'
              years='2022'
            />
            <EducationRow
              institution='Birla Senior Secondary School, Pilani'
              degree='High School (10th)'
              years='2020'
            />

            <tr>
              <td colSpan="2">
                <h1 className={`text-3xl lg:text-5xl ml-10 lg:ml-40 mt-20 mb-10`}>Projects</h1>
              </td>
            </tr>
            <ProjectRow
              title='Decentralized Trading Platform on Aptos | Inter IIT Tech Meet 12.0'
              description='Led development of a decentralized trading platform on Aptos blockchain, achieving 1st position. Integrated Econia protocol and Uniswap for secure and efficient asset exchange with Petra Wallet for user authentication.'
              skills={['Aptos', 'ReactJS', 'FastAPI', 'Blockchain']}
              links="https://github.com/DeepakSilaych/aptotrade"
            />
            <ProjectRow
              title='Smart Surveillance System | Techfest 2023'
              description='Developed a generative AI-based surveillance system to enhance video quality, integrated object and face recognition using YOLO, and anonymized faces in public feeds using OpenCV.'
              skills={['Generative AI', 'YOLO', 'OpenCV']}
              links="https://github.com/DeepakSilaych/tf_urbanfuturism"
            />
            <ProjectRow
              title='Hackathon Team Formation Platform | HackOn Blocks 2024'
              description='Built a decentralized platform for team creation in hackathons, integrating MetaMask wallet and deploying smart contracts on Ethereum and Polygon.'
              skills={['Next.js', 'Ethereum', 'Polygon', 'IPFS']}
              links=""
            />
            <ProjectRow
              title='Real-Time Sign Language Recognizer'
              description='Developed a real-time sign language recognition system using a CNN model, achieving 97% accuracy with TensorFlow and MediaPipe for hand tracking.'
              skills={['TensorFlow', 'Keras', 'OpenCV', 'MediaPipe']}
              links="https://github.com/DeepakSilaych/SignLanguageRecogniser_Realtime"
            />
            <ProjectRow
              title='ITC Certificate Portal'
              description='Developed a portal using React and Django to manage certificates for over 20 events, integrating Single Sign-On authentication using JWT tokens.'
              skills={['React', 'Django', 'JWT']}
              links="https://itc.gymkhana.iitb.ac.in/certificates/"
            />
          </tbody>
        </table>
      </div>
      <footer className={`${styles.footer} text-center py-2 md:py-4 lg:py-6 mt-10 md:mt-20`}>
        <p>© 2024 Deepak Silaych. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Main;

const SideLinks = () => {
  return (
    <div className={`${styles.sidelinks} fixed right-0 bottom-0`}>
      <a href="https://github.com/DeepakSilaych" target="_blank" rel="noopener noreferrer"><Github /></a>
      <a href="https://www.linkedin.com/in/deepaksilaych/" target="_blank" rel="noopener noreferrer"><Linkedin /></a>
      <a href="https://www.instagram.com/deepaksilaych/" target="_blank" rel="noopener noreferrer"><Instagram /></a>
      <a href="https://t.me/deepaksilaych" target="_blank" rel="noopener noreferrer"><Send /></a>
      <a href="mailto:deepaksilaych@gmail.com" target="_blank" rel="noopener noreferrer"><Mail /></a>
    </div>
  );
}

const ExperienceRow = ({ date, title, description, skills }) => (
  <>
    <tr className={`${styles.experiencerow} mb-5`}>
      <td className={`${styles.date} w-1/6 text-base md:text-lg`}>{date}</td>
      <td>
        <h2 className='text-base md:text-lg'>{title}</h2>
      </td>
    </tr>
    <tr className={`${styles.experiencerow} mb-5`}>
      <td className='w-1/6'></td>
      <td>
        <p className='text-xs md:text-sm w-full md:w-2/3 lg:w-1/2'>{description}</p>
        <ul className='flex flex-wrap mt-2 md:mt-4 mb-8'>
          {skills.map((skill, index) => (
            <li key={index} className={`${styles.experienceskills} text-xs md:text-sm rounded-3xl mr-2 mb-2`}>
              {skill}
            </li>
          ))}
        </ul>
      </td>
    </tr>
  </>
);

const EducationRow = ({ institution, degree, years }) => (
  <tr className={`${styles.educationrow} mb-5`}>
    <td className={`${styles.date} w-1/6 text-base md:text-lg`}>{years}</td>
    <td>
      <h2 className='text-base md:text-lg'>{institution}</h2>
      <p className='text-xs md:text-sm'>{degree}</p>
    </td>
  </tr>
);

const ProjectRow = ({ title, description, skills, links }) => (
  <>
    <tr className={`${styles.projectrow} mb-5`}>
      <td></td>
      <td>
        <h2 className='text-base md:text-lg'>
          <a href={links} target="_blank" rel="noopener noreferrer">{title}</a>
        </h2>
      </td>
    </tr>
    <tr className={`${styles.projectrow} mb-5`}>
      <td></td>
      <td>
        <p className='text-xs md:text-sm w-full md:w-2/3 lg:w-1/2'>{description}</p>
        <ul className='flex flex-wrap mt-2 md:mt-4 mb-8'>
          {skills.map((skill, index) => (
            <li key={index} className={`${styles.projectskills} text-xs md:text-sm rounded-3xl mr-2 mb-2`}>
              {skill}
            </li>
          ))}
        </ul>
      </td>
    </tr>
  </>
);
