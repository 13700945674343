import React from 'react'
import './hiya.css'
import i1 from './img/i1.png'
import i2 from './img/i2.png'
import i3 from './img/i3.png'
import i4 from './img/i4.png'
import i5 from './img/i5.png'
import i6 from './img/i6.png'
import i7 from './img/i7.png'
import i8 from './img/i8.png'
import i9 from './img/i9.png'
import i10 from './img/i10.png'
import i11 from './img/i11.png'
import i12 from './img/i12.png'

function Hiya() {
  return (
    <>
    {window.innerWidth > 768 ?
    <div className=" hiya fixed top-0 left-0 bg-purple-800 w-full min-h-screen flex justify-center flex-col text-center">
      <p className="text-7xl font-sans font-bold text-white my-0 z-10">Happy Birthday</p> 
      <p className="text-7xl font-sans font-bold text-zinc-200 my-1 z-10">My Favourite Footballer</p>
      <img src={i1} className='w-40 fixed right-1/2 bottom-0 animate-bounce '/>
      <img src={i2} className='w-60 fixed right-24 bottom-72'/>
      <img src={i3} className='w-100 fixed right-36 -bottom-16 shake'/>
      <img src={i4} className='w-60 fixed -right-1 top-0 shake'/>
      <img src={i5} className='w-100 fixed right-0 -bottom-56 rotate-180 shake hiyadee'/>
      <img src={i6} className='w-80 fixed right-1/4 -top-16 shake'/>
      <img src={i7} className='w-60 fixed right-1/3 bottom-0 shake'/>
      <img src={i8} className='w-60 fixed right-1/2 top-0 shake'/>
      <img src={i9} className='w-60 fixed left-0 top-0 shake'/>
      <img src={i11} className='w-80 fixed bottom-0 left-10' alt="" />
      <img src={i12} className='w-80 fixed bottom-0 right-2/3' alt="" />
    </div>
    :
    <div className='fixed top-0 left-0 bg-blue-700 w-full min-h-screen flex justify-center text-center'>
      <p className="text-4xl font-sans font-bold text-white my-0">Chupchap laptop me khol :)</p>
    </div>
    }
    </>
  )
}

export default Hiya