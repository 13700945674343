// CursorBackground.js
import React, { useState, useEffect } from 'react';
import style from './main.module.css';

const CursorBackground = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className={`${style.cursor} z-0`} style={{ left: position.x, top: position.y }}>
    </div>
  );
};

export default CursorBackground;
